import React, { Component } from 'react'
import Select from 'react-select'

export class SupportLevelSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    handleChange = (e) => {
        const changeFromProps = this.props.onChange;
        if (changeFromProps) {
            changeFromProps(e, this.props.name);
        }
    };

    render() {
        const options = (() => {
            return [...Array(5).keys()].map(i => i).map(i => ({ value: i, label: `${i}突破` }))
        })();
        return (
            <Select value={ options.find(it => it.value === this.props.value) }
                onChange={ this.handleChange } options={ options }
                isSearchable={ false }
            />
        );
    }
}

export default SupportLevelSelect;
