import React, { Component } from 'react'
import Select from 'react-select'
import umaData from './../../uma';
import { findUmaSkillName } from './../tool/UmaTool';

export class UmaSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    handleChange = (e) => {
        const changeFromProps = this.props.onChange;
        if (changeFromProps) {
            changeFromProps(e, this.props.name);
        }
    };

    umaData = umaData.map(it => {
        const skillName = findUmaSkillName(it.value)
        const n = { label: `${it.label} | ${skillName}` }
        return { ...it, ...n }
    })

    render() {
        return (
            <Select value={ this.umaData.find(it => it.value === this.props.value) ?? null }
                onChange={ this.handleChange } options={ this.umaData } />
        );
    }
}

export default UmaSelect;
