import './App.css';

import {
    BrowserRouter,
    Routes,
    Route,
    useParams,
} from "react-router-dom";
import { useEffect } from 'react';

import UserFindForm from './components/UserFindForm';
import UserPage from './components/UserPage';
import AddUserForm from './components/AddUserForm';
import UmaDataTest from './components/test/UmaDataTest';
import News from './components/other/News';
import Layout from './Layout'

function App() {
    useEffect(() => {
        document.title = '繁中服db';
    });

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={ <Layout /> } >
                <Route index element={ <UserFindForm /> } />
                <Route path="/find" element={ <UserFindForm /> } />
                <Route path="/add" element={ <AddUserForm /> } />
                <Route path="/news" element={ <News /> } />
                <Route path="/test" element={ <UmaDataTest /> } />
                <Route path="/user/:id" element={ <Wrapper /> } />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

const Wrapper = (props) => {
    const params = useParams()
    return <UserPage id={ params.id } />
}

export default App;
