import React, { Component } from 'react'
import factorData from './../../factor';
import umaData from './../../uma';

export class UmaDataTest extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <div>
            {
                umaData.map((x, i) => {
                    return this.umaSkill(x)
                })
            }
            </div>
        );
    }

    umaSkill = (uma) => {
        const skill = factorData.find(it => it.value === uma.skill).label
        return (
            <div>
                { uma.label } - { skill }
            </div>
        )
    }
}

export default UmaDataTest;
