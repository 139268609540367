import React, { Component } from 'react'
import Select from 'react-select'

export class SelectRange extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    handleChange = (e) => {
        const changeFromProps = this.props.onChange;
        if (changeFromProps) {
            changeFromProps(e, this.props.name);
        }
    };

    options =
        [
            { value: 1, label: '全部' },
            { value: 2, label: '本體' },
            { value: 3, label: '父母' },
            { value: 4, label: '任意一個', mode: 'factor' },
            { value: 5, label: '父母任意一個', mode: 'factor' },
            { value: 6, label: '二個各自星數', mode: 'factor' },
            { value: 7, label: '三個各自星數', mode: 'factor' },
        ]

    render() {
        const options = this.options.filter(it => {
            const mode = it.mode ?? 'all'
            return mode === 'all' || mode === this.props.mode
        })

        return (
            <Select value={ this.options.find(it => it.value === this.props.value) }
                onChange={ this.handleChange } options={ options }
                isSearchable={ false }
            />
        );
    }
}

export default SelectRange;
