import React, { Component } from "react";
import FactorSelectForm from './FactorSelectForm';
import UmaSelectForm from './UmaSelectForm';
import { findUmaSkillName } from './tool/UmaTool';

export class AddUmaForm extends Component {
    handleInputChange = (name, value) => {
        const { inputData } = this.props;
        inputData[name] = value;
        this.onInputChange(inputData);
    };

    handleSelectChange = (e, name) => {
        const { value } = e;
        const { inputData } = this.props;
        inputData[name] = value;
        this.onInputChange(inputData);
    };

    onInputChange = (newInputList) => {
        const onInputChange = this.props.onInputChange;
        if (onInputChange) {
            onInputChange(this.props.name, newInputList);
        }
    }

    convertData = (data) => {
        return { selectUmas: data.selectUmas, selectUmasExclude: data.selectUmasExclude,
            selectFactors: [...data.selectFactorB, ...data.selectFactorR, ...data.selectFactorG, ...data.selectFactorW] }
    }

    render() {
        const { inputData } = this.props;

        return (
            <div className="flexContainer2">
                <div>
                    馬娘
                </div>
                <UmaSelectForm name="selectUmas" mode="selectSingle"
                    onInputChange={ this.handleInputChange } inputList={ inputData.selectUmas } />

                <div>
                    能力因子
                </div>
                <FactorSelectForm factorC="b" name="selectFactorB" mode="addSingle"
                    onInputChange={ this.handleInputChange } inputList={ inputData.selectFactorB } />

                <div>
                    適性因子
                </div>
                <FactorSelectForm factorC="r" name="selectFactorR" mode="addSingle"
                    onInputChange={ this.handleInputChange } inputList={ inputData.selectFactorR }/>

                <div>
                    固有技能因子 { findUmaSkillName(inputData.selectUmas.find(Boolean)?.umaType) }
                </div>
                <FactorSelectForm factorC="g" name="selectFactorG" mode="addSingleOrNone"
                    onInputChange={ this.handleInputChange } inputList={ inputData.selectFactorG }/>

                <div>
                    白因子
                </div>
                <FactorSelectForm factorC="w" name="selectFactorW" mode="addMutil"
                    onInputChange={ this.handleInputChange } inputList={ inputData.selectFactorW }/>
            </div>
        );
    }
}

export default AddUmaForm;
