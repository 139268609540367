import React, { Component } from 'react'
import LoadingIndicator from './LoadingIndicator';
import { trackPromise } from 'react-promise-tracker';
import UserRow from './UserRow';

export class UserPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        this.startFind()
    }

    startFind = () => {
        trackPromise(
            fetch('/api/user/id', this.requestOptions())
                .then(async response => {
                    const isJson = response.headers.get('content-type')?.includes('application/json');
                    const data = isJson && await response.json();

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response status
                        const error = (data && data.message) || response.status;
                        return Promise.reject(error);
                    }

                    this.setState({ resData: data, errorMessage: '' })
                })
                .catch(error => {
                    this.setState({ resData: [], errorMessage: `查詢失敗:${error.toString()}` });
                    console.error('There was an error!', error);
                })
        )
    }

    requestOptions = () => {
        return {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ id: this.props.id })
        }
    }

    render() {
        const { resData, errorMessage } = this.state

        return (
            <div className="mainC">
                <LoadingIndicator/>
                <UserView resData={ resData } />
                <div style={{ marginTop: 20 }}>{ errorMessage }</div>
            </div>
        );
    }
}

const UserView = (props) => {
    const { resData } = props;
    if (resData?.length > 0) {
        return (
            <UserRow userData={ resData.find(Boolean) } />
        )
    } else if (resData?.length === 0) {
        return (<h1>無資料</h1>)
    } else {
        return null
    }
}

export default UserPage;
