import React, { Component } from 'react'
import Select from 'react-select'

import factorData from './../../factor';

export class FactorSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    handleChange = (e) => {
        const changeFromProps = this.props.onChange;
        if (changeFromProps) {
            changeFromProps(e, this.props.name);
        }
    };

    factorData = [...factorData, ...customs]

    render() {
        const filterType = this.props.factorC;
        const filterF = it => {
            if (!filterType) {
                return true;
            }

            return it.c === filterType;
        }

        const optionsF = it => {
            const mode = it.mode ?? 'all'
            return mode === 'all' || mode === this.props.mode
        }

        return (
            <Select value={ this.factorData.find(it => it.value === this.props.value) ?? null }
                onChange={ this.handleChange } options={ this.factorData.filter(filterF).filter(optionsF) }
                isSearchable={ this.props.isSearchable ?? true }
            />
        );
    }
}

const customs = [
    { value: -1, label: '能力', c: 'b', mode: 'find' },
    { value: -2, label: '相同能力', c: 'b', mode: 'find' },
]

export default FactorSelect;
