import React, { Component } from 'react'
import Select from 'react-select'

export class FactorLevelSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    handleChange = (e) => {
        const changeFromProps = this.props.onChange;
        if (changeFromProps) {
            changeFromProps(e, this.props.name);
        }
    };

    render() {
        const options = (() => {
            if (this.props.isAdd) {
                return [...Array(3).keys()].map(i => i + 1).map(i => ({ value: i, label: `★${i}` }))
            } else {
                return [...Array(9).keys()].map(i => i + 1).map(i => ({ value: i, label: `★${i}` }))
            }
        })();
        return (
            <Select value={ options.find(it => it.value === this.props.value) }
                onChange={ this.handleChange } options={ options }
                isSearchable={ false }
            />
        );
    }
}

export default FactorLevelSelect;
