import React from 'react'
import { Link } from "react-router-dom";
import umaData from './../uma';
import factorData from './../factor';
import supportData from './../support';
import Card from 'react-bootstrap/Card';
import { BoxArrowUpRight } from 'react-bootstrap-icons';

function UserRow(props) {
    const userData = props.userData;
    const factors = userData.umas.map(it => {
        it.factors.forEach(x => x.relation = it.relation)
        return it.factors
    }).flat()
    const itselfFactors = factors.filter(it => it.relation === 1)
    const parentsFactors = factors.filter(it => it.relation !== 1)
    const url = `/user/${userData.uuid}`
    return (
        <Card>
            <Card.Header>{ userData.uuid }
                <LinkView mode={ props.mode } url={ url } />
            </Card.Header>
            <Card.Body>
                <div className="flexContainerUserRow">
                <UmasGroupView umas={ userData.umas } wins={ userData.wins }
                    support= { userData.support }
                    supportLevel= { userData.supportLevel }
                    />
                <FactorGroupView itselfFactors={ itselfFactors } parentsFactors={ parentsFactors } />
                </div>
            </Card.Body>
            <Card.Footer>{ userData.memo }</Card.Footer>
        </Card>

    );
}

function FactorGroupView(props) {
    const itselfFactors = props.itselfFactors;
    const parentsFactors = props.parentsFactors;
    const distinct = (list) => {
        return [...new Set(list)];
    }
    const types = distinct([...itselfFactors, ...parentsFactors].map(it => it.type));
    const typeB = types.filter(it => factorData.find(v => v.value === it && v.c === 'b'));
    const typeR = types.filter(it => factorData.find(v => v.value === it && v.c === 'r'));
    const typeG = types.filter(it => factorData.find(v => v.value === it && v.c === 'g'));
    const typeW = types.filter(it => factorData.find(v => v.value === it && v.c === 'w'));

    const factorClassName = "flexContainerFactor"

    return (
        <ul className="factorGroupView">
            <ul className={ factorClassName }>
                {
                    typeB.map(it => {
                        return (
                            <FactorView type={ it } itselfFactors={ itselfFactors } parentsFactors={ parentsFactors } />
                        )
                    })
                }
            </ul>

            <ul className={ factorClassName }>
                {
                    typeR.map(it => {
                        return (
                            <FactorView type={ it } itselfFactors={ itselfFactors } parentsFactors={ parentsFactors } />
                        )
                    })
                }
            </ul>

            <ul className={ factorClassName }>
                {
                    typeG.map(it => {
                        return (
                            <FactorView type={ it } itselfFactors={ itselfFactors } parentsFactors={ parentsFactors } />
                        )
                    })
                }
            </ul>

            <ul className={ factorClassName }>
                {
                    typeW.map(it => {
                        return (
                            <FactorView type={ it } itselfFactors={ itselfFactors } parentsFactors={ parentsFactors } />
                        )
                    })
                }
            </ul>
        </ul>
    )
}

function FactorView(props) {
    const itselfFactors = props.itselfFactors;
    const parentsFactors = props.parentsFactors;
    const type = props.type;
    const factor = factorData.find(it => it.value === type)
    const factorC = factor?.c ?? "w"
    const name = factor?.label ?? '無此資料';
    const pLevel = parentsFactors.filter(it => it.type === type).map(it => it.level).reduce((prev, curr) => prev + curr, 0);
    const pCount = parentsFactors.filter(it => it.type === type).length;
    const iLevel = itselfFactors.find(it => it.type === type)?.level ?? 0;
    const iCount = itselfFactors.filter(it => it.type === type).length;
    const tLevel = pLevel + iLevel;
    const tCount = pCount + iCount;
    const iLevelName = () => {
        if (iLevel > 0) {
            return ` (本體★${iLevel})`;
        } else {
            return '';
        }
    };

    const cName = `factorItem factorItem-${factorC} factorItem-${tCount}`;

    return (
        <li className={ cName }>
                { `${name} ★${tLevel}${iLevelName()}` }
                <div className="tooltiptext">
                    <ul>
                        { [...itselfFactors, ...parentsFactors].filter(it => it.type === type).map(it => {
                            const s = () => {
                                if (it.relation === 1) {
                                    return '本體';
                                } else if (it.relation === 2) {
                                    return '父母1';
                                } else {
                                    return '父母2';
                                }
                            };
                            return (
                                <li>{ s() }★{ it.level }</li>
                            )
                        }) }
                    </ul>
                </div>
        </li>
    );
}

function UmasGroupView(props) {
    const umas = props.umas;
    const support = props.support;
    const supportLevel = props.supportLevel;
    const r1 = umas.find(it => it.relation === 1)
    return (
        <ul className="umasGroupView">
            <UmaView uma={ r1 } />
            <ul>
                {
                    umas.filter(it => it.relation !== 1).map(it => {
                        return (<UmaView uma={ it } />)
                    })
                }
            </ul>
            <li>勝場數 { props.wins }</li>
            <SupportView support={ support } supportLevel={ supportLevel } />
        </ul>
    );
}

function UmaView(props) {
    const uma = props.uma;
    const name = umaData.find(it => it.value === uma?.type)?.label ?? '無此資料'
    return (
        <li>{ name }</li>
    );
}

function SupportView(props) {
    const support = props.support;
    const supportLevel = props.supportLevel;
    const s = supportData.find(it => it.value === support)
    if (s) {
        return (
            <li>
                支援卡
                <ul>
                    <li>{ supportLevel }突破</li>
                    <li>{ s.label }</li>
                </ul>
            </li>
        );
    } else {
        return null
    }
}

const LinkView = (props) => {
    const hasLink = props.mode === 'find'
    if (hasLink) {
        return (
            <Link style={{ margin: 10 }} to={ props.url } target="_blank">
                <BoxArrowUpRight/>
            </Link>
        )
    } else {
        return null
    }
}

export default UserRow;
