import React, { Component } from "react";
import FactorSelectForm from './FactorSelectForm';
import UmaSelectForm from './UmaSelectForm';
import SupportSelectForm from './SupportSelectForm';
import UserRow from './UserRow';
import LoadingIndicator from './LoadingIndicator';
import { Search } from 'react-bootstrap-icons';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import type { ReactTabsFunctionComponent, TabProps } from 'react-tabs';
import Button from 'react-bootstrap/Button';
import { trackPromise } from 'react-promise-tracker';

export class UserFindForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inputData: {
                selectUmas: [],
                selectUmasExclude: [],
                selectSupports: [],
                selectFactorB: [],
                selectFactorR: [],
                selectFactorG: [],
                selectFactorW: [],
            },
            wins: 0,
            resData: [],
            errorMessage: ''
        };
    }

    handleInputChange = (name, value) => {
        const { inputData } = this.state;
        inputData[name] = value;
        this.setState({
            inputData: inputData
        });
    };

    handleChange = input => e => {
        if (input === 'wins') {
            const value = Number(e.target.value)
            this.setState({
                [input]: value,
            });
        } else {
            this.setState({
                [input]: e.target.value,
            });
        }
    };

    convertData = (data) => {
        return {
            wins: this.state.wins,
            selectSupports: data.selectSupports,
            selectUmas: data.selectUmas,
            selectUmasExclude: data.selectUmasExclude,
            selectFactors: [...data.selectFactorB, ...data.selectFactorR, ...data.selectFactorG, ...data.selectFactorW] }
    }

    render() {
        const { inputData, resData, errorMessage } = this.state;

        return (
            <div className="mainC">
                <div className="flexContainer2">
                    <div>
                        賽馬娘
                    </div>
                    <UmaSelectForm name="selectUmas" onInputChange={ this.handleInputChange } inputList={ inputData.selectUmas } />

                    <div>
                        排除賽馬娘
                    </div>
                    <UmaSelectForm name="selectUmasExclude" onInputChange={ this.handleInputChange } inputList={ inputData.selectUmasExclude } />

                    <div>
                        支援卡
                    </div>
                    <SupportSelectForm name="selectSupports" onInputChange={ this.handleInputChange } inputList={ inputData.selectSupports } />

                    <div>
                        能力因子
                    </div>
                    <FactorSelectForm factorC="b" name="selectFactorB" mode="find"
                        onInputChange={ this.handleInputChange } inputList={ inputData.selectFactorB } />

                    <div>
                        適性因子
                    </div>
                    <FactorSelectForm factorC="r" name="selectFactorR" onInputChange={ this.handleInputChange } inputList={ inputData.selectFactorR }/>

                    <div>
                        固有技能因子
                    </div>
                    <FactorSelectForm factorC="g" name="selectFactorG" onInputChange={ this.handleInputChange } inputList={ inputData.selectFactorG }/>

                    <div>
                        白因子
                    </div>
                    <FactorSelectForm factorC="w" name="selectFactorW" onInputChange={ this.handleInputChange } inputList={ inputData.selectFactorW }/>

                    <div>
                        勝場數
                        <input type="number" value={ this.state.wins } onChange={ this.handleChange('wins') } />
                    </div>
                </div>

                <Button variant="primary" size="lg" onClick={ this.startFind }>
                    <Search/>
                </Button>

                <h1>結果數量: { resData.length }</h1>
                <LoadingIndicator/>
                <div style={{ marginTop: 20 }}><h1>{errorMessage}</h1></div>

                <div>
                    <Results resData={ resData } />
                </div>
            </div>
        );
    }

    startFind = () => {
        trackPromise(
            fetch('/api/user/list', this.requestOptions())
                .then(async response => {
                    const isJson = response.headers.get('content-type')?.includes('application/json');
                    const data = isJson && await response.json();

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response status
                        const error = (data && data.message) || response.status;
                        return Promise.reject(error);
                    }

                    this.setState({ resData: data, errorMessage: '' })
                })
                .catch(error => {
                    this.setState({ resData: [], errorMessage: `查詢失敗:${error.toString()}` });
                    console.error('There was an error!', error);
                })
        )
    }

    requestOptions = () => {
        return {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(this.convertData(this.state.inputData))
        }
    };
}

function ResultTab(props) {
    const { resData } = props;
    return (
            <div className="flexContainer2">
            {
                resData.map((x, i) => {
                    return (
                        <UserRow userData={ x } mode="find" />
                    )
                })
            }
            </div>
    )
}

const CustomTab: ReactTabsFunctionComponent<TabProps> = ({ children, ...otherProps }) => (
    <TabPanel {...otherProps}>
        {children}
    </TabPanel>
);

CustomTab.tabsRole = 'TabPanel';

function* chunks(arr, n) {
    for (let i = 0; i < arr.length; i += n) {
        yield arr.slice(i, i + n);
    }
}

function Results(props) {
    const { resData } = props;
    const chunksList = [...chunks(resData, 20)];

    return (
        <Tabs>
        <TabList>
        {
            chunksList.map((x, i) => {
                return (
                    <Tab>{ i + 1 }</Tab>
                )
            })
        }
        </TabList>

        {
            chunksList.map((x, i) => {
                return (
                    <CustomTab>
                    <ResultTab resData={ x } />
                    </CustomTab>
                )
            })
        }

        </Tabs>
    );
}

export default UserFindForm;
