import React from "react";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function MyNavbar() {
    return (
        <Navbar bg="primary" variant="dark">
        <Container>
        <Navbar.Brand href="/find">繁中服db</Navbar.Brand>
        <Nav className="me-auto">
        <Nav.Link href="/find">查詢</Nav.Link>
        <Nav.Link href="/add">登記</Nav.Link>
        </Nav>
        </Container>
        </Navbar>
    );
}

export default MyNavbar;
