import React, { Component } from "react";
import FactorSelect from './factor/FactorSelect';
import FactorLevelSelect from './factor/FactorLevelSelect';
import SelectRange from './select/SelectRange';
import { PlusLg, XLg } from 'react-bootstrap-icons';
import factorData from './../factor';
import Select from 'react-select'

export class FactorSelectForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mutilSelected: [],
            inputSearch: '',
        };
    }

    handleInputSearch = (v, a) => {
        if (a.action === "input-change")
            this.setState({ inputSearch: v })
    }

    handleMutilSelected = (v) => {
        this.setState({ mutilSelected: v })
    }

    handleMutilSelectClose = () => {
        const { inputList } = this.props;
        const newO = (() => {
            return this.state.mutilSelected.map((it) => {
                if (this.isAdd()) {
                    return { factorType:it.value, factorTypeLevel: 1 }
                } else {
                    return { factorType:it.value, factorTypeLevel: 1, factorTypeRange: 1 }
                }
            })
        })();
        this.onInputChange([...inputList, ...newO]);
        this.setState({ mutilSelected: [] })
    }

    filterOption = (candidate, input) => {
        const isIncludesSubstrings = (s, t) => {
            let result = false
            for (let i = 0; i < t.length; i++) {
                const start = t.substring(0, i + 1)
                const other = t.substring(i + 1)
                result = result || (s.includes(start) && s.includes(other))
            }
            return result
        }

        const filter = (s, t) => s.includes(t) || isIncludesSubstrings(s, t)

        const parts = input.split('|').map(it => it.toLowerCase()).filter(it => it !== '');
        if (parts.length > 0) {
            return parts.some(it => filter(candidate.label.toLowerCase(), it))
        } else {
            return true
        }
    };

    handleSelectChange = (e, name, index) => {
        const { value } = e;
        const { inputList } = this.props;
        inputList[index][name] = value;
        this.onInputChange(inputList);
    };

    handleRemoveClick = index => {
        const { inputList } = this.props;
        inputList.splice(index, 1);
        this.onInputChange(inputList);
    };

    handleAddClick = () => {
        const { inputList } = this.props;
        const newO = (() => {
            if (this.isAdd()) {
                return { factorTypeLevel: 1 }
            } else {
                return { factorTypeLevel: 1, factorTypeRange: 1 }
            }
        })();
        this.onInputChange([...inputList, newO]);
    };

    onInputChange = (newInputList) => {
        const onInputChange = this.props.onInputChange;
        if (onInputChange) {
            onInputChange(this.props.name, newInputList);
        }
    }

    isAdd = () => {
        return ["addSingle", "addSingleOrNone", "addMutil"].includes(this.props.mode);
    }

    render() {
        const addSingle = this.props.mode === "addSingle"
        const isAdd = this.isAdd();
        const isAddSingleOrNone = this.props.mode === "addSingleOrNone"
        const isFactorG = this.props.name === "selectFactorG";
        const isFactorW = this.props.name === "selectFactorW";
        const isSearchable = ["selectFactorG", "selectFactorW"].includes(this.props.name);

        const inputList = ((it) => {
            if (it.length === 0 && addSingle) {
                return [{ factorTypeLevel: 1}];
            } else {
                return it;
            }
        })(this.props.inputList ?? []);

        const filterType = this.props.factorC;
        const filterF = it => {
            if (!filterType) {
                return true;
            }

            return it.c === filterType;
        }

        return (
            <div>
            {
                inputList.map((x, i) => {
                    return (
                        <div className="flexContainer">
                            { !(isAddSingleOrNone && isFactorG) &&
                            <div className="factorSelectType">
                                <FactorSelect name="factorType" value={ x.factorType } factorC={ this.props.factorC }
                                    mode={ this.props.mode }
                                    onChange={ (e, n) => this.handleSelectChange(e, n, i) }
                                    isSearchable={ isSearchable }
                                />
                            </div>
                            }

                            <div className="factorSelectLevel">
                                <FactorLevelSelect name="factorTypeLevel" isAdd={ isAdd }
                                    value={ x.factorTypeLevel } onChange={ (e, n) => this.handleSelectChange(e, n, i) } />
                            </div>

                            { !isAdd &&
                                <div className="factorSelectRange">
                                    <SelectRange name="factorTypeRange"
                                        value={ x.factorTypeRange } onChange={ (e, n) => this.handleSelectChange(e, n, i) }
                                        mode="factor"
                                    />
                                </div>
                            }

                            { !addSingle &&
                            <button onClick={ () => this.handleRemoveClick(i) }>
                                <XLg/>
                            </button>
                            }
                        </div>
                    );
            })}

            { isFactorW &&
                <Select
                    placeholder="多選查詢篩選eg. 領彎|領直|秋娘"
                    value={ this.state.mutilSelected }
                    isMulti={ true }
                    closeMenuOnSelect={ false }
                    inputValue={ this.state.inputSearch }
                    onInputChange={ this.handleInputSearch }
                    filterOption= { this.filterOption }
                    onMenuClose={ this.handleMutilSelectClose }
                    onChange={ this.handleMutilSelected }
                    options={ factorData.filter(filterF) } />
            }

            { !(addSingle || (isAddSingleOrNone && inputList.length === 1)) &&
                <button onClick={ this.handleAddClick }>
                    <PlusLg/>
                </button>
            }
            </div>
        );
    }
}

export default FactorSelectForm;
