import React, { Component } from "react";
import UmaSelect from './uma/UmaSelect';
import SelectRange from './select/SelectRange';
import { PlusLg, XLg } from 'react-bootstrap-icons';

export class UmaSelectForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    handleSelectChange = (e, name, index) => {
        const { value } = e;
        const { inputList } = this.props;
        inputList[index][name] = value;
        this.onInputChange(inputList);
    };

    handleRemoveClick = index => {
        const { inputList } = this.props;
        inputList.splice(index, 1);
        this.onInputChange(inputList);
    };

    handleAddClick = () => {
        const { inputList } = this.props;
        this.onInputChange([...inputList, this.makeForAdd()]);
    };

    makeForAdd = () => {
        if (this.isAdd()) {
            return {}
        } else {
            return { selectRange: 1 }
        }
    }

    onInputChange = (newInputList) => {
        const onInputChange = this.props.onInputChange;
        if (onInputChange) {
            onInputChange(this.props.name, newInputList);
        }
    }

    isAdd = () => {
        return ["addSingle", "addSingleOrNone", "addMutil"].includes(this.props.mode);
    }

    render() {
        const isSelectSingle = this.props.mode === "selectSingle";
        const isAdd = this.isAdd();

        const { inputList } = this.props;

        return (
            <div>
            {
                inputList.map((x, i) => {
                    return (
                        <div className="flexContainer">
                            <div className="factorSelectType">
                                <UmaSelect name="umaType" value={ x.umaType }
                                    onChange={ (e, n) => this.handleSelectChange(e, n, i) } />
                            </div>

                            { !(isAdd || isSelectSingle) &&
                                <div className="factorSelectRange">
                                    <SelectRange name="selectRange" value={ x.selectRange } onChange={ (e, n) => this.handleSelectChange(e, n, i) } />
                                </div>
                            }

                            { !isSelectSingle &&
                            <button onClick={ () => this.handleRemoveClick(i) }>
                                <XLg/>
                            </button>
                            }
                        </div>
                    );
            })}

            { !isSelectSingle &&
                <button onClick={ this.handleAddClick }>
                    <PlusLg/>
                </button>
            }
            </div>
        );
    }
}

export default UmaSelectForm;
