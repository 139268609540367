import React, { Component } from 'react';

import AddUmaForm from './AddUmaForm';
import SupportSelectForm from './SupportSelectForm';
import UserRow from './UserRow';
import LoadingIndicator from './LoadingIndicator';
import umaData from './../uma';
import factorData from './../factor';

import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { trackPromise } from 'react-promise-tracker';

export class AddUserForm extends Component {
    state = {
        step: 1,
        inputUuid: '',
        inputWins: 0,
        inputMemo: '',
        inputData: {
            selectSupport: [{}],
            uma1: {
                relation: 1,
                selectUmas: [{}],
                selectFactorB: [{ factorTypeLevel: 1 }],
                selectFactorR: [{ factorTypeLevel: 1 }],
                selectFactorG: [],
                selectFactorW: [],
            },
            uma2: {
                relation: 2,
                selectUmas: [{}],
                selectFactorB: [{ factorTypeLevel: 1 }],
                selectFactorR: [{ factorTypeLevel: 1 }],
                selectFactorG: [],
                selectFactorW: [],
            },
            uma4: {
                relation: 4,
                selectUmas: [{}],
                selectFactorB: [{ factorTypeLevel: 1 }],
                selectFactorR: [{ factorTypeLevel: 1 }],
                selectFactorG: [],
                selectFactorW: [],
            }
        }
    };

    handleInputChange = (name, value) => {
        const { inputData } = this.state;
        inputData[name] = value;
        this.setState({
            inputData: inputData,
            isAddUmasDone: this.isAddUmasDone(inputData)
        });
    };

    goStep = (s) => {
        this.setState({
            step: s
        });
    };

    nextStep = () => {
        const { step } = this.state;
        this.setState({
            step: step + 1
        });
    };

    prevStep = () => {
        const { step } = this.state;
        this.setState({
            step: step - 1
        });
    };

    handleChange = input => e => {
        if (input === 'inputWins') {
            const value = Number(e.target.value)
            this.setState({
                [input]: value,
                isWinsValid: this.checkUuid(value)
            });
        } else {
            if (input === 'inputUuid') {
                const value = e.target.value
                this.setUuid(value)
            } else {
                this.setState({
                    [input]: e.target.value,
                });
            }
        }
    };

    step = () => {
        const { step } = this.state;

        switch (step) {
            case 1:
                return this.inputUuidForm();
            case 2:
                return this.newUserPwForm();
            case 3:
                return this.userForm();
            case 4:
                return this.confirmForm();
            case 5:
                return this.successForm();
            case 102:
                return this.oldUserPwForm();
            case 103:
                return this.userRetrieveForm();
            default:
                (console.log('This is a multi-step form built with React.'))
        }
    }

    convertToUserRow = () => {
        const { inputData } = this.state;

        const makeNewUma = (old) => {
            const type = old.selectUmas.find(it => it).umaType;
            const skillType = umaData.find(it => it.value === type).skill
            const fB = old.selectFactorB.filter(it => it.factorTypeLevel && it.factorType)
                .map(it => {
                    return { type: it.factorType, level: it.factorTypeLevel}
                })
            const fR = old.selectFactorR.filter(it => it.factorTypeLevel && it.factorType)
                .map(it => {
                    return { type: it.factorType, level: it.factorTypeLevel}
                })
            const fW = old.selectFactorW.filter(it => it.factorTypeLevel && it.factorType)
                .map(it => {
                    return { type: it.factorType, level: it.factorTypeLevel}
                })
            const fG = old.selectFactorG.filter(it => it.factorTypeLevel)
                .map(it => {
                    return { type: skillType, level: it.factorTypeLevel}
                })

            const fAll = [...fB, ...fR, ...fW, ...fG]
            const factors = []
            fAll.forEach(item => {
                const i = factors.findIndex(x => x.type === item.type)
                if (i <= -1) {
                    factors.push(item);
                }
            })

            return {
                type: type,
                relation: old.relation,
                factors: factors
            };
        }

        return {
            uuid: this.getUuidInput(),
            pw: this.state.inputPw,
            wins: Number(this.state.inputWins),
            support: this.state.inputData.selectSupport.find(it => it)?.support,
            supportLevel: this.state.inputData.selectSupport.find(it => it)?.supportLevel,
            memo: this.state.inputMemo,
            umas: [
                makeNewUma(inputData.uma1),
                makeNewUma(inputData.uma2),
                makeNewUma(inputData.uma4),
            ]
        }
    }

    successForm = () => {
        return (
            <div>
                登記成功
            </div>
        )
    }

    confirmForm = () => {
        const userData = this.convertToUserRow();
        const errorMessage = this.state.errorMessage ?? '';
        return (
            <div>
                <Button variant="primary" size="lg" onClick={ this.prevStep }>修改</Button>
                <UserRow userData={ userData } />

                <Button variant="primary" size="lg" onClick={ this.startAddUserData }>完成填寫</Button>
                <LoadingIndicator/>
                <div style={{ marginTop: 20 }}>{errorMessage}</div>
            </div>
        )
    }

    userRetrieveForm = () => {
        const userData = this.state.userRetrieve
        const errorMessage = this.state.errorMessage ?? '';
        return (
            <div>
                <UserRow userData={ userData } />

                <Button variant="primary" size="lg" onClick={ this.startModifyData }>修改</Button>
                <LoadingIndicator/>
                <div style={{ marginTop: 20 }}>{errorMessage}</div>
            </div>
        )
    }

    startModifyData = () => {
        const inputData = this.convertToInputData();
        inputData.step = 3
        inputData.isAddUmasDone = true
        this.setState(inputData);
    }

    convertToInputData = () => {
        const userData = this.state.userRetrieve

        const makeNewUma = (model) => {
            const relation = model.relation;
            const selectUmas = [{ umaType: model.type }]


            const selectFactorB = model.factors.filter(it => factorData.find(v => v.value === it.type && v.c === 'b'))
                .map(it => {
                    return { factorType: it.type, factorTypeLevel: it.level }
                })

            const selectFactorR = model.factors.filter(it => factorData.find(v => v.value === it.type && v.c === 'r'))
                .map(it => {
                    return { factorType: it.type, factorTypeLevel: it.level }
                })

            const selectFactorG = model.factors.filter(it => factorData.find(v => v.value === it.type && v.c === 'g'))
                .map(it => {
                    return { factorType: it.type, factorTypeLevel: it.level }
                })

            const selectFactorW = model.factors.filter(it => factorData.find(v => v.value === it.type && v.c === 'w'))
                .map(it => {
                    return { factorType: it.type, factorTypeLevel: it.level }
                })

            return {
                relation: relation,
                selectUmas: selectUmas,
                selectFactorB: selectFactorB,
                selectFactorR: selectFactorR,
                selectFactorG: selectFactorG,
                selectFactorW: selectFactorW,
            };
        }

        return {
            inputWins: userData.wins,
            inputMemo: userData.memo,
            inputData: {
                selectSupport: [{ support: userData.support, supportLevel: userData.supportLevel }],
                uma1: makeNewUma(userData.umas.find(it => it.relation === 1)),
                uma2: makeNewUma(userData.umas.find(it => it.relation === 2)),
                uma4: makeNewUma(userData.umas.find(it => it.relation === 4)),
            },
        }
    }

    inputUuidForm = () => {
        const errorMessage = this.state.errorMessage;

        return (
            <div>
                <Row className="mb-3">
                    <Form.Group as={ Col } md="4" controlId="validationCustom01">
                        <Form.Label>uuid</Form.Label>
                        <Form.Control required type="text" placeholder="uuid" onChange={ this.handleChange('inputUuid') }
                        isInvalid={ !(this.state.isUuidValid ?? false) }
                        value={ this.state.inputUuid }
                        />
                    </Form.Group>
                </Row>

                <Button variant="primary" size="lg" onClick={ this.startRetrieveUser }>下一步</Button>
                <LoadingIndicator/>
                <div style={{ marginTop: 20 }}>{ errorMessage }</div>
            </div>
        )
    }

    handleSubmitEmail = (e) => {
        const form = e.currentTarget;
        e.preventDefault();
        e.stopPropagation();

        if (form.checkValidity()) {
        }

        this.setState({
            validated: true
        });
    }

    checkUserValidForm = () => {
        return (
            <div>
                <Form.Group as={Col} md="4" controlId="validationCustom01">
                    <Form.Label>uuid</Form.Label>
                    <Form.Control disabled type="text" placeholder="uuid" onChange={ this.handleChange('inputUuid') }
                    isInvalid={ !(this.state.isUuidValid ?? false) }
                    value={ this.state.inputUuid }
                    />
                </Form.Group>

                <Form noValidate validated={ this.state.validated ?? false } onSubmit={ this.handleSubmitEmail }>
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                        <Form.Label>Email</Form.Label>
                        <Form.Control required type="email" placeholder="name@example.com" onChange={ this.handleChange('inputEmail') }
                        value={ this.state.inputEmail }
                        />
                    </Form.Group>
                    <Button type="submit">下一步</Button>
                </Form>
            </div>
        )
    }

    handleSubmitNewUserPw = (e) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            validated: true
        });

        if ((this.state.inputPw?.length ?? 0) === 0 || this.state.inputPw !== this.state.inputPwConfirm) {
        } else {
            this.nextStep();
        }
    }

    newUserPwForm = () => {
        return (
            <div>
                <Row className="mb-3">
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                        <Form.Label>uuid</Form.Label>
                        <Form.Control disabled type="text" placeholder="uuid" onChange={ this.handleChange('inputUuid') }
                        isInvalid={ !(this.state.isUuidValid ?? false) }
                        value={ this.state.inputUuid }
                        />
                    </Form.Group>
                </Row>

                <Form noValidate onSubmit={ this.handleSubmitNewUserPw }>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                            <Form.Label>密碼</Form.Label>
                            <Form.Control required type="password" onChange={ this.handleChange('inputPw') }
                            placeholder="新資料建立密碼"
                            value={ this.state.inputPw }
                            isInvalid={ (this.state.inputPw?.length ?? 0) === 0 }
                            />
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                            <Form.Label>確認密碼</Form.Label>
                            <Form.Control required type="password" onChange={ this.handleChange('inputPwConfirm') }
                            value={ this.state.inputPwConfirm }
                            isInvalid={ this.state.inputPw !== this.state.inputPwConfirm }
                            />
                        </Form.Group>
                    </Row>
                    <Button type="submit">下一步</Button>
                </Form>
            </div>
        )
    }

    handleRetrievePw = (e) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            validated: true
        });

        if ((this.state.inputPw?.length ?? 0) === 0) {
        } else {
            this.startRetrievePw();
        }
    }

    oldUserPwForm = () => {
        const errorMessage = this.state.errorMessage ?? '';
        return (
            <div>
                <Row className="mb-3">
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                        <Form.Label>uuid</Form.Label>
                        <Form.Control disabled type="text" placeholder="uuid" onChange={ this.handleChange('inputUuid') }
                        isInvalid={ !(this.state.isUuidValid ?? false) }
                        value={ this.state.inputUuid }
                        />
                    </Form.Group>
                </Row>

                <Form noValidate onSubmit={ this.handleRetrievePw }>
                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                            <Form.Label>密碼</Form.Label>
                            <Form.Control required type="password" onChange={ this.handleChange('inputPw') }
                            placeholder="密碼"
                            value={ this.state.inputPw }
                            isInvalid={ (this.state.inputPw?.length ?? 0) === 0 }
                            />
                        </Form.Group>
                    </Row>
                    <Button type="submit">下一步</Button>
                    <LoadingIndicator/>
                    <div style={{ marginTop: 20 }}>{ errorMessage }</div>
                </Form>
            </div>
        )
    }

    startRetrievePw = () => {
        const userData = { uuid: this.getUuidInput(), pw: this.state.inputPw }

        trackPromise(
            fetch('/api/user/retrievePw', this.makeRequestOptions(userData))
                .then(async (response) => {
                    const isJson = response.headers.get('content-type')?.includes('application/json');
                    const data = isJson && await response.json();

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response status
                        const error = (data && data.message) || response.status;
                        return Promise.reject(error);
                    }

                    if (data.status === 0) {
                        this.setState({ errorMessage: '',
                            userRetrieve: data.user,
                            step: 103
                        });
                    }
                })
                .catch(error => {
                    this.setState({ errorMessage: `失敗:${error.toString()}` });
                    console.error('There was an error!', error);
                })
        )
    }

    startRetrieveUser = () => {
        const userData = { uuid: this.getUuidInput() }

        trackPromise(
            fetch('/api/user/retrieve', this.makeRequestOptions(userData))
                .then(async (response) => {
                    const isJson = response.headers.get('content-type')?.includes('application/json');
                    const data = isJson && await response.json();

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response status
                        const error = (data && data.message) || response.status;
                        return Promise.reject(error);
                    }

                    if (data.status === 0) {
                        const step = (isUserRetrieve) => {
                            if (isUserRetrieve) {
                                return 102;
                            } else {
                                return 2;
                            }
                        }
                        this.setState({ errorMessage: '',
                            isUserRetrieve: data.retrieve,
                            step: step(data.retrieve),
                        });
                    }
                })
                .catch(error => {
                    this.setState({ errorMessage: `失敗:${error.toString()}` });
                    console.error('There was an error!', error);
                })
        )
    }

    startAddUserData = () => {
        const userData = this.convertToUserRow();

        trackPromise(
            fetch('/api/user', this.makeRequestOptions(userData))
                .then(async response => {
                    const isJson = response.headers.get('content-type')?.includes('application/json');
                    const data = isJson && await response.json();

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response status
                        const error = (data && data.message) || response.status;
                        return Promise.reject(error);
                    }

                    if (data.status === 0) {
                        this.setState({ errorMessage: '' });
                        this.saveUuid();
                        this.nextStep();
                    }
                })
                .catch(error => {
                    this.setState({ errorMessage: `失敗:${error.toString()}` });
                    console.error('There was an error!', error);
                })
        )
    }

    makeRequestOptions = (data) => {
        return {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        }
    };

    userForm = () => {
        return (
            <div>
                { this.userInfoForm() }
                { this.umaTabs() }
                <div className="tmpHeight" />
            </div>
        )
    }

    isNumeric = (value) => {
        return /^\d+$/.test(value);
    }

    checkUuid = (value) => {
        return this.isNumeric(value);
    }

    isAddUmasDone = (umasData) => {
        const distinct = (list) => {
            return [...new Set(list)];
        }

        const checkUmaData = (data) => {
            const isSelectUma = data.selectUmas.find(it => it.umaType);
            const isSelectFactorB = data.selectFactorB.find(it => it.factorType);
            const isSelectFactorR = data.selectFactorR.find(it => it.factorType);
            return isSelectUma && isSelectFactorB && isSelectFactorR;
        }

        const { uma1, uma2, uma4 } = umasData;
        const isUmaUni = distinct([uma1, uma2, uma4].map(it => it.selectUmas).flat().filter(it => it.umaType).map(it => it.umaType)).length === 3;
        return isUmaUni &&
            checkUmaData(uma1) && checkUmaData(uma2) && checkUmaData(uma4);
    }

    handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (
            (this.state.isUuidValid ?? false) &&
            (this.state.isWinsValid ?? true) &&
            (this.state.isAddUmasDone ?? false)
        ) {
            this.nextStep();
        }
    };

    userInfoForm = () => {
        const { inputData } = this.state;
        return (
                <Form noValidate onSubmit={ this.handleSubmit }>
                    <Row className="mb-3">
                    <Form.Group as={Col} md="4" controlId="validationCustom01">
                        <Form.Label>uuid</Form.Label>
                        <Form.Control disabled type="text" placeholder="uuid" onChange={ this.handleChange('inputUuid') }
                        isInvalid={ !(this.state.isUuidValid ?? false) }
                        value={ this.state.inputUuid }
                        />
                    </Form.Group>

                    <Form.Group as={Col} md="4" controlId="validationCustom02">
                        <Form.Label>勝場數</Form.Label>
                        <Form.Control required type="number" placeholder="勝場數" onChange={ this.handleChange('inputWins') }
                        isInvalid={ !(this.state.isWinsValid ?? true) }
                        value={ this.state.inputWins }
                        />
                    </Form.Group>
                    </Row>

                    <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                        <Form.Label>備註</Form.Label>
                        <Form.Control type="text" placeholder="選填" onChange={ this.handleChange('inputMemo') }
                        value={ this.state.inputMemo }
                        />
                    </Form.Group>
                    </Row>


                    <Row className="mb-3">
                    <div>
                        支援卡
                    </div>
                    <SupportSelectForm name="selectSupport" mode="addSingle"
                        onInputChange={ this.handleInputChange } inputList={ inputData.selectSupport } />
                    </Row>

                    <Row className="mb-3">
                    <Form.Group as={Col} md="2" controlId="validationCustom04">
                    <Button type="submit">下一步</Button>
                    </Form.Group>

                    <Form.Group as={Col} md="3" controlId="validationCustom05">
                    <Form.Check required label="馬娘資料完成" feedback="填完馬娘資料" feedbackType="invalid"
                        checked={ (this.state.isAddUmasDone ?? false) }
                        disabled={ true }
                        isInvalid={ !(this.state.isAddUmasDone ?? false) }
                    />
                    </Form.Group>

                    <Form.Group as={Col} md="2" controlId="validationCustom06">
                    <Button onClick={ this.resetUmas }>重置馬娘資料</Button>
                    </Form.Group>
                    </Row>


             </Form>
        )
    }

    umaTabs = () => {
        return (
            <Tabs>
                <TabList>
                    <Tab>本體馬娘</Tab>
                    <Tab>繼承父母1</Tab>
                    <Tab>繼承父母2</Tab>
                </TabList>
                <TabPanel>
                    <AddUmaForm name="uma1"
                        onInputChange={ this.handleInputChange }
                        inputData={ this.state.inputData.uma1 } />
                </TabPanel>
                <TabPanel>
                    <AddUmaForm name="uma2"
                        onInputChange={ this.handleInputChange }
                        inputData={ this.state.inputData.uma2 } />
                </TabPanel>
                <TabPanel>
                    <AddUmaForm name="uma4"
                        onInputChange={ this.handleInputChange }
                        inputData={ this.state.inputData.uma4 } />
                </TabPanel>
            </Tabs>
        )
    }

    resetUmas = () => {
        const { inputData } = this.state;
        inputData.uma1 = {
            relation: 1,
            selectUmas: [{}],
            selectFactorB: [{ factorTypeLevel: 1 }],
            selectFactorR: [{ factorTypeLevel: 1 }],
            selectFactorG: [],
            selectFactorW: [],
        }

        inputData.uma2 = {
            relation: 2,
            selectUmas: [{}],
            selectFactorB: [{ factorTypeLevel: 1 }],
            selectFactorR: [{ factorTypeLevel: 1 }],
            selectFactorG: [],
            selectFactorW: [],
        }

        inputData.uma4 = {
            relation: 4,
            selectUmas: [{}],
            selectFactorB: [{ factorTypeLevel: 1 }],
            selectFactorR: [{ factorTypeLevel: 1 }],
            selectFactorG: [],
            selectFactorW: [],
        }

        this.setState({
            inputData: inputData,
            isAddUmasDone: false
        });
    }

    getUuidInput = () => {
        return Number(this.state.inputUuid)
    }

    getUuidLocal = () => {
        return localStorage.getItem('uuid')
    }

    saveUuid = () => {
        localStorage.setItem('uuid', this.getUuidInput())
    }

    setUuid = (uuid) => {
        this.setState({
            inputUuid: uuid,
            isUuidValid: this.checkUuid(uuid)
        })
    }

    componentDidMount() {
        const uuid = this.getUuidLocal()
        this.setUuid(uuid)
    }

    render() {
        return (
            <div className="mainC">
                { this.step() }
            </div>
        )
    }
}

export default AddUserForm;
