import React from "react";

import { usePromiseTracker } from "react-promise-tracker";
import { Audio } from  'react-loader-spinner'

function LoadingIndicator() {
    const { promiseInProgress } = usePromiseTracker();

    return promiseInProgress &&
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
            }}
        >
            <Audio
                height = "80"
                width = "80"
                radius = "9"
                color = 'green'
                ariaLabel = 'three-dots-loading'
            />
        </div>
}

export default LoadingIndicator;
