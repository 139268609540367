import React, { Component } from 'react'
import Select from 'react-select'

import supportData from './../../support';

export class SupportSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    handleChange = (e) => {
        const changeFromProps = this.props.onChange;
        if (changeFromProps) {
            changeFromProps(e, this.props.name);
        }
    };

    supportData = supportData.map(it => {
        const n = { label: `${it.rank} | ${it.label} (${it.type})` }
        return { ...it, ...n }
    })

    render() {
        return (
            <Select value={ this.supportData.find(it => it.value === this.props.value) }
                onChange={ this.handleChange } options={ this.supportData } />
        );
    }
}

export default SupportSelect;
