import React, { Component } from "react";
import SupportSelect from './support/SupportSelect';
import SupportLevelSelect from './support/SupportLevelSelect';
import { PlusLg, XLg } from 'react-bootstrap-icons';

export class SupportSelectForm extends Component {
    handleSelectChange = (e, name, index) => {
        const { value } = e;
        const { inputList } = this.props;
        inputList[index][name] = value;
        this.onInputChange(inputList);
    };

    handleRemoveClick = index => {
        const { inputList } = this.props;
        inputList.splice(index, 1);
        this.onInputChange(inputList);
    };

    handleAddClick = () => {
        const { inputList } = this.props;
        const newO = (() => {
            if (this.isAdd()) {
                return { support_level: 0 }
            } else {
                return { support_level: 1 }
            }
        })();
        this.onInputChange([...inputList, newO]);
    };

    onInputChange = (newInputList) => {
        const onInputChange = this.props.onInputChange;
        if (onInputChange) {
            onInputChange(this.props.name, newInputList);
        }
    }

    isAdd = () => {
        return ["addSingle", "addSingleOrNone", "addMutil"].includes(this.props.mode);
    }

    render() {
        const addSingle = this.props.mode === "addSingle"
        const isAdd = this.isAdd();
        const isAddSingleOrNone = this.props.mode === "addSingleOrNone"

        const inputList = ((it) => {
            if (it.length === 0 && addSingle) {
                return [{}];
            } else {
                return it;
            }
        })(this.props.inputList ?? []);

        return (
            <div>
            {
                inputList.map((x, i) => {
                    return (
                        <div className="flexContainer">
                            { !isAddSingleOrNone &&
                            <div className="supportSelect">
                                <SupportSelect name="support" value={ x.support }
                                    onChange={ (e, n) => this.handleSelectChange(e, n, i) } />
                            </div>
                            }

                            <div className="supportSelectLevel">
                                <SupportLevelSelect name="supportLevel" isAdd={ isAdd }
                                    value={ x.supportLevel } onChange={ (e, n) => this.handleSelectChange(e, n, i) } />
                            </div>

                            { !addSingle &&
                            <button onClick={ () => this.handleRemoveClick(i) }>
                                <XLg/>
                            </button>
                            }
                        </div>
                    );
            })}

            { !(addSingle || (isAddSingleOrNone && inputList.length === 1)) &&
                <button onClick={ this.handleAddClick }>
                    <PlusLg/>
                </button>
            }
            </div>
        );
    }
}

export default SupportSelectForm;
