import React, { Component } from 'react'

export class News extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        return (
            <div>
                <div>
                恢復資料為11/07 到12/04期間有私過遺失密碼的 也有一併處理
                </div>
                <div>
                對於備份資料會再完善
                </div>
            </div>
        );
    }
}

export default News;
